.forgottab,.forgottab:hover{
    cursor: pointer !important;
}
/*React Fixes*/
body.loginbody #root, body.loginbody #root>div {
    height: 100%;
    width: 100%;
    display: block;
  }

body.loginbody label.highlight{
      background:transparent;
  }
 
body.loginbody input{
    padding:10px;
}  
body.loginbody .tab-content h1 {
  font-weight: 400;
}